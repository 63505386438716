<template>
  <div class="page-container">
    <page-header></page-header>
    <page-table ref="table" show-paginate :get-data="dataPage" :fields="fields" :search-model="searchModel"
                :item-actions="actions" @onActionClick="handleItemActionClick">
      <template slot="search-items">
        <el-form-item label="" prop="signType">
          <el-select v-model="searchModel.signType" clearable placeholder="电子签类型" style="width: 130px">
            <el-option v-for="(label, value) in signTypeMap" :key="value" :label="label" :value="value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="" prop="activation">
          <el-select v-model="searchModel.activation" clearable placeholder="激活状态" style="width: 120px;">
            <el-option v-for="(label, value) in activationMap" :key="value" :label="label" :value="value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="" prop="name" style="width: 120px;">
          <el-input v-model="searchModel.name" placeholder="开发商名称"></el-input>
        </el-form-item>
      </template>
    </page-table>
    <!--印章列表-分页-->
    <el-dialog title="印章列表" :visible.sync="seal_dialog_visible" width="50%"
               :close-on-click-modal="false"
               :close-on-press-escape="false" :modal-append-to-body="false">
      <el-table :data="sealTable" border style="width: 100%">
        <el-table-column fixed prop="sealName" label="印章名称" width="150"></el-table-column>
        <el-table-column prop="sealId" label="印章ID"></el-table-column>
        <el-table-column fixed="right" label="操作" width="100" align="center">
          <template slot-scope="scope">
            <el-button @click="handleSealAuth(scope.row)" type="text" size="small">授权</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div style="padding-top: 20px;">
        <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="sealQuery.page"
                :page-sizes="[10, 20]"
                :page-size="20"
                layout="total, sizes, prev, pager, next, jumper"
                :total="sealQuery.total">
        </el-pagination>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader"
import PageTable from "@/components/PageTable"
import {signDataPage, signatureWithdeveloperId, orgEmpowerAuth, orgSealPage, orgSealAuth} from "@/api/common"
import {BASE_URI} from "@/utils/config"


const signTypeMap = {
  "0": "腾讯电子签",
  "1": "e签宝",
}
const activationMap = {
  "0": "未激活",
  "1": "已激活",
}

export default {
  name: "ElectronicContracts",
  data() {
    return {
      BASE_URI: BASE_URI,
      searchModel: {
        name: '',
        activation: '',
        signType: '',
      },
      dataPage: signDataPage,
      fields: [
        {label: 'OriginId', key: 'id',},
        {label: '开发商名称', key: 'name'},
        {
          label: '电子签类型',width: '100',
          render({signType}, h) {
            return h('div', signType == '1' ? 'e签宝' : '腾讯电子签')
          }
        },
        {
          label: '激活状态', align: 'center',width: '110',
          render({signType, tencentAppId, eorgId}, h) {
            if (signType == 1) {
              return eorgId ? h('span', {attrs: {class: 'color-blue'}}, '已激活') : h('span', '未激活')
            } else {
              return tencentAppId ? h('span', {attrs: {class: 'color-blue'}}, '已激活') : h('span', '未激活')
            }
          }
        },
        {label: '经办人个数', key: 'userSize', align: 'center',width: '100',},
        {label: '签约份数', key: 'signatureSize', align: 'center',width: '100',},
        {label: '激活时间', key: 'tencentAuthTime', align: 'center', width: 170},
        {label: '授权到期日期', key: 'eexpireTime', align: 'center', width: 170},
        {label: '印章到期日期', key: 'esealsExpireTime', align: 'center', width: 170},
      ],
      actions: [
        {
          action: 'eAuth', label: '授权', type: 'primary', showAction(item) {
            return item.signType == 1 ? item.eorgId : false
          }
        },
        {
          action: 'control', label: '进入控制台', type: 'primary', showAction(item) {
            return item.signType == 1 ? item.eorgId : item.tencentAppId
          }
        },
        {
          action: 'activation', label: '去激活', type: 'primary', showAction(item) {
            if (item.signType == 1) {
              return !item.eorgId
            } else {
              return !item.tencentAppId
            }
          }
        },
        {
          action: 'eSeal', label: '印章授权', type: 'primary', showAction(item) {
            return item.signType == 1 && item.eorgId
          }
        },
      ],
      signTypeMap: signTypeMap,
      activationMap: activationMap,
      // 印章
      seal_dialog_visible: false,
      cur_developersId: null,
      sealQuery: {
        limit: 20, // 小于等于20
        page: 1,
        total: 0
      },
      sealTable: [],
    }
  },
  components: {
    PageHeader,
    PageTable
  },
  created() {

  },
  mounted() {

  },
  activated() {
    this.$refs.table && this.$refs.table.loadData()
  },
  methods: {
    handleItemActionClick(action, item) {
      let that = this
      that[action + 'Action'](item)
    },
    activationAction(item) {
      this.controlAction(item)
    },
    // 进入控制台
    controlAction(item) {
      signatureWithdeveloperId(item.id).then(res => {
        window.open(res, "_blank");
      })
    },
    // e签宝授权
    eAuthAction(item) {
      orgEmpowerAuth(item.id).then(res => {
        window.open(res, "_blank");
      })
    },
    // 点击印章授权
    eSealAction(item) {
      let that = this
      orgSealPage(item.id, that.sealQuery).then(res => {
        that.cur_developersId = item.id
        that.sealTable = res.records || []
        that.sealQuery.total = Number(res.total)
        that.seal_dialog_visible = true
      }).catch(() => {
        that.cur_developersId = null
        that.sealTable = []
        that.seal_dialog_visible = false
      })
    },
    // 印章授权
    handleSealAuth(item) {
      let that = this
      orgSealAuth(that.cur_developersId, item.sealId).then(res => {
        that.seal_dialog_visible = false
        window.open(res, "_blank");
      })
    },
    handleSizeChange(e) {
      this.sealQuery.limit = Number(e)
      this.eSealAction({id: this.cur_developersId})
    },
    handleCurrentChange(e) {
      this.sealQuery.page = Number(e)
      this.eSealAction({id: this.cur_developersId})
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
